import React, { useContext } from 'react';
import MessagingIcon from '../../images/messaging-icon.svg';
import MessagingContext from '../../../../../contexts/User/messaging-context';
import handleNavigationClick from '../../../../../utils/navigation/handleNavigationClick';
import styles from './Messaging.module.scss';

const notificationCountStyles = ({ unreadNotificationCount }) => `
  header-nav-notification-counter
  ${styles.NotificationCounter}
  ${Number(unreadNotificationCount) > 0 ? styles.HasItems : ''}
`;

const Messaging = (props) => {
  const {
 className, messagingRoom, unreadNotificationCount, isConflictingStyle
} = props;

  const { isOpen, setIsOpen, setRoomId } = useContext(MessagingContext);

  const toggleMessaging = () => {
    if (!isOpen) {
      handleNavigationClick('messaging')();
      setIsOpen(true);
      setRoomId(messagingRoom);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <button
      className={`${styles.Messaging} ${className}`}
      data-client-messaging-launch-room-id={messagingRoom}
      onClick={toggleMessaging}
      type="button"
    >
      <div className={notificationCountStyles(props)}>{unreadNotificationCount}</div>
      <div className={isConflictingStyle ? styles.isConflicting : ''}>
        <MessagingIcon />
      </div>
    </button>
  );
};
export default Messaging;
