/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import UserContext from '@context/User/user-context';
import { userInExperimentVariation } from '@lib/experiment';
import { ExperimentTitles } from '@models/Experiment';
import isBrowser from '@utils/browser/isBrowser';
import styles from './Header.module.scss';
import roleConfigs from './config';

const getHeaderStyles = (props) => {
  const {
    isTransparent,
    isOpaque,
    isFixed,
    isScrolled,
    isNavOpen,
    bannerExists,
    isHiddenMobile,
  } = props;

  return `
    Havenly-Navigation-Header
    ${styles.Header}
    ${isScrolled ? styles.isScrolled : ''}
    ${isTransparent && !isNavOpen ? styles.isTransparent : ''}
    ${isOpaque ? styles.isOpaque : ''}
    ${isFixed ? styles.isFixed : ''}
    ${isHiddenMobile ? styles.isHiddenMobile : ''}
    ${bannerExists ? styles.hasBanner : ''}
  `;
};

const handleNavToggle = (isNavOpen, setIsNavOpen) => {
  if (isNavOpen) {
    setIsNavOpen(false);
    document.body.className = document.body.className.replace(styles.NoScroll, '');
  } else {
    setIsNavOpen(true);
    document.body.className = `${document.body.className} ${styles.NoScroll}`;
  }
};

const handleScroll = (setIsScrolled, lastScrollPosition, setLastScrollPosition) => {
  const scrollPosition = window.scrollY;
  if (scrollPosition > 50) {
    setIsScrolled(true);
  } else {
    setIsScrolled(false);
  }
  setLastScrollPosition(scrollPosition);
};

const Header = (props) => {
  const {
    unreadNotificationCount,
    cartItemCount,
    hideMessaging,
    showGetStarted = true,
    shopShopMegaMenu = true,
    cities
  } = props;

  const { user, experimentVariations } = useContext(UserContext);

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [lastScrollPositionState, _setLastScrollPosition] = useState(0);

  const lastScrollPosition = useRef(lastScrollPositionState);
  const setLastScrollPosition = (diff) => {
    lastScrollPosition.current = diff;
    _setLastScrollPosition(diff);
  };

  useEffect(() => {
    // Add scroll event listener
    const scrollListener = () => {
      handleScroll(
        setIsScrolled,
        lastScrollPosition.current,
        setLastScrollPosition,
      );
    };
    const scrollListenerOptions = {
      capture: true,
      passive: true,
    };
    window.addEventListener(
      'scroll',
      scrollListener,
      scrollListenerOptions,
    );

    // Monkey patch pushState to add in a custom event.
    const { pushState } = window.history;
    window.history.pushState = (...args) => {
      // Fire off the default behavior of pushState
      pushState.apply(window.history, args);

      // Dispatch the event.
      window.dispatchEvent(new Event('pushstate'));
    };

    return function cleanup() {
      window.removeEventListener('scroll', scrollListener, scrollListenerOptions);
    };
  }, []);

  let config = roleConfigs[user?.role]?.({ cities })
  || roleConfigs.loggedOut({ cities });

  function shouldHideMessaging() {
    if (hideMessaging) {
      return true;
    } else {
      return userInExperimentVariation(
        ExperimentTitles.CORE_EXPERIENCE_TEST_A, experimentVariations
      );
    }
  }

  if (shouldHideMessaging()) {
    config = config.filter((component) => component.key !== 'Messaging');
  }

  if (!showGetStarted) {
    config = config.filter((component) => component.key !== 'Button');
  }

  // check for the existence of the promotional banner to offset
  const bannerElement = isBrowser() ? document.getElementsByClassName('promotional-banner__container') : [];

  return (
    <div
      className={ getHeaderStyles({
        ...props,
        isScrolled,
        user,
        cartItemCount,
        unreadNotificationCount,
        isNavOpen,
        bannerExists: bannerElement.length > 0,
      })}
      data-test={`nav__role-${user?.role || 'logged-out'}`}
    >
      <div
        className={`
          ${styles.ToggleMenu}
          ${isNavOpen ? styles.isOpen : ''}
        `}
        onClick={ () => handleNavToggle(isNavOpen, setIsNavOpen)}
        data-test="configurable-nav__toggle-mobile"
      >
        <div className={ styles.Line } />
        <div className={ styles.Line } />
        <div className={ styles.Line } />
      </div>
      {config.map(
        (Item) => {
          const Component = Item.component;

          const componentProps = {
            ...props,
            ...Item.props,
            isNavOpen,
            isScrolled,
            key: Item.key,
            user,
            cartItemCount,
            unreadNotificationCount,
            shopShopMegaMenu,
            cities
          };

          return (
            <Component { ...componentProps } />
          );
        },
      )}
    </div>
  );
};

export default Header;
