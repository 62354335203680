import React, {
 ReactElement, useContext, useEffect, useState
} from 'react';
import { useLazyQuery } from '@apollo/client';

import MOST_RECENT_ROOM from '@graphql/User/queries/get-most-recent-room-for-user.graphql';
import CART_QUERY from '@graphql/User/queries/get-cart-for-user.graphql';
import dynamic from 'next/dynamic';
import { IMostRecentRoomResponse } from '@models/User/i-most-recent-room-response';
import { ICartResponse } from '@models/User/i-cart-response';
import { Role } from '@models/shared/i-auth-response';
import isBrowser from '../../../utils/browser/isBrowser';
import browser from '../../../utils/browser/browser';
import UserContext from '../../../contexts/User/user-context';
import Header from './components/Header';
import handleNavigationClick from '../../../utils/navigation/handleNavigationClick';

import { IShopNavigationMenu } from '../shop/ShopNavigationMenu';

export interface INavigationProps {
  isFixed?: boolean;
  isTransparent?: boolean;
  isConflictingVariant?: boolean;
  hideMessaging?: boolean;
  hideNavLinks?: boolean;
  navLinksOverride?: () => ReactElement;
  afterLoginRedirectUrl?: string;
  isOpaque?: boolean;
  isHiddenMobile?: boolean;
  onSearch?: (search: string) => void;
  showGetStarted?: boolean;
  showSearch?: boolean;
  shopNavigationMenu?: IShopNavigationMenu[];
  shopShopMegaMenu?: boolean;
  merchGroups?: any;
  shopGroups?: any;
  cities?: string[];
  // hack, we need to include subnav inside of our nav
  // to fix stacking context problems
  hasSubNav?: boolean;
}

export default function Navigation(props: INavigationProps) {
  const { user } = useContext(UserContext);

  const role = user?.role;

  const messagingRoom = useGetMostRecentRoomForUser(role);

  const cartItemCount = useGetCartItemCount(role);

  // don't display nav when rendered in an ios webview
  const [isIosNative, setIsIosNative] = useState(false);
  useEffect(() => {
    const userAgent = (window && window.navigator && window.navigator.userAgent) || '';
    setIsIosNative(browser.isIosNativeApp(userAgent));
  }, []);
  if (isBrowser() && isIosNative) {
    return null;
  }

  // if admin or designer, return legacy nav
  // if we need to make changes to this
  // please update the components in this dir instead
  if (role === 'admin' || role === 'designer') {
    const Nav = dynamic(() => import('../NavigationDEPRECATED/Nav'));
    return <Nav />;
  }

  return (
    <>
      <Header
        {...props}
        messagingRoom={ messagingRoom }
        cartItemCount={ cartItemCount }
        unreadNotificationCount={0}
        onClickNavItem={ (title: string, prefix: string) => handleNavigationClick(title, prefix)() }
      />
    </>
  );
}

export function useGetMostRecentRoomForUser(role?: Role) {
  const [
    getMostRecentRoom,
    { data: mostRecentRoomResponse },
  ] = useLazyQuery<IMostRecentRoomResponse>(MOST_RECENT_ROOM);

  useEffect(() => {
    if (role === 'customer') {
      getMostRecentRoom();
    }
  }, [role]);

  let defaultRoomId;
  if (mostRecentRoomResponse && mostRecentRoomResponse.mostRecentRoomForUser) {
    defaultRoomId = mostRecentRoomResponse.mostRecentRoomForUser.id;
  }

  return defaultRoomId;
}

export function useGetCartItemCount(role?: Role) {
  const [
    getMostRecentRoom,
    { data: cartQueryResponse },
  ] = useLazyQuery<ICartResponse>(CART_QUERY);

  useEffect(() => {
    if (role === 'customer') {
      getMostRecentRoom();
    }
  }, [role]);

  let totalProducts = 0;
  if (cartQueryResponse && cartQueryResponse.cartForUser) {
    totalProducts = cartQueryResponse.cartForUser.cartProducts
      .reduce((acc, cur) => acc + cur.quantity, 0);
  }

  return totalProducts;
}

