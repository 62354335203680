import React, { ReactChild } from 'react';
import { useTheme } from '@components/theme';
import FacebookIcon from '@components/shared/images/atoms/FacebookIcon/FacebookIcon';
import TwitterIcon from '@components/shared/images/atoms/TwitterIcon/TwitterIcon';
import InstagramIcon from '@components/shared/images/atoms/InstagramIcon/InstagramIcon';
import PinterestIcon from '@components/shared/images/atoms/PinterestIcon/PinterestIcon';
import trackLinkClick from '../trackLinkClick';
import styles from './FollowUs.module.scss';

interface ISocial {
  id: string;
  link: string;
  icon: ReactChild;
}
const FollowUs = () => {
  const theme = useTheme();
  const networks: ISocial[] = [
    {
      id: 'facebook',
      link: 'https://www.facebook.com/TheHavenly',
      icon: <FacebookIcon />
    },
    {
      id: 'twitter',
      link: 'https://twitter.com/theHavenly',
      icon: <TwitterIcon />
    },
    {
      id: 'instagram',
      link: 'https://www.instagram.com/theHavenly',
      icon: <InstagramIcon />
    },
    {
      id: 'pinterest',
      link: 'https://www.pinterest.com/thehavenly',
      icon: <PinterestIcon />
    },
  ];

  return (
    <div className={styles.FollowUs}>
      <p className={theme.styles.Title3}>Follow Us</p>
      <ul className={styles.Body}>
        {
          networks.map((social: ISocial) => (
            <li
              key={social.id}
            >
              <a
                className={styles.SocialLink}
                href={social.link}
                target="_blank"
                rel="noreferrer"
                onClick={trackLinkClick(social.id)}
                aria-label={social.id}
              >
                {social.icon}
              </a>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default FollowUs;
