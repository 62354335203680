import React from 'react';
import Logo from './logo.svg';

const HavenlyLogo = ({ className }: { className: string }) => (
  <div
    className={className}
    role="img"
    aria-label="Havenly Logo"
  >
    <Logo />
  </div>
);

export default HavenlyLogo;
