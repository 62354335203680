import React from 'react';
import { getResizedImageUrl, ResizedImageOpts } from '@lib/image';
import NextImage, { ImageLoaderProps, ImageProps } from 'next/image';

interface IIImageProps extends ImageProps, ResizedImageOpts {
  fit?: undefined | string;
}

/**
 * Image component wrapper for Next.js Image component.
 * If width is provided, must also provide height, or layout="fill"
 *
 * Ref: https://nextjs.org/docs/basic-features/image-optimization
 */
const Image = ({
  src: initialSrc,
  width: initialWidth,
  height: initialHeight,
  loading,
  fit,
  ...props
}: IIImageProps) => {
  const prismicLoader = ({ src, width, quality }: ImageLoaderProps) => {
    const w = width ? `&w=${width}` : '';
    const q = quality ? `&q=${quality}` : '';
    return `${src}${w}${q}`;
  };

  const thumborLoader = ({ src, width, quality }: ImageLoaderProps) => {
    // returns thumbor url
    return getResizedImageUrl({
      quality: quality || 100,
      url: src as string,
      width,
      fit
    });
  };

  const isPrismic = typeof initialSrc === 'string' && initialSrc.includes('prismic');
  const loader = isPrismic ? prismicLoader : thumborLoader;

  const blurredPrismicImage = `${initialSrc}&w=30&q=50`;
  const blurredThumborImage = getResizedImageUrl({
    quality: 50,
    url: initialSrc as string,
    fit,
    width: 30,
  });

  return (
    <NextImage
      { ...props}
      alt={props.alt || ''}
      loading={loading || 'lazy'}
      src={initialSrc}
      loader={loader}
      width={initialWidth}
      height={initialHeight}
      blurDataURL={isPrismic ? blurredPrismicImage : blurredThumborImage}
    />
  );
};

export default Image;
